export const routes = {
	dashboard: {
		path: "/dashboard",
		label: "Dashboard",
		hide: false,
	},
	tariffs: {
		path: "/tariffs",
		label: "Ladekarten",
		hide: false,
	},
	documentation: {
		path: "/documentation",
		label: "API Doc",
		hide: false,
	},
	operators: {
		path: "/operators",
		label: "CPO",
		hide: false,
	},
	import: {
		path: "/import",
		label: "Chargeprice",
		hide: false,
	},
	// metrics: {
	// 	path: "/server",
	// 	label: "Server",
	// 	hide: false,
	// },
	login: {
		path: "/login",
		label: "Login",
		hide: true,
	},
} as const;

export interface AppRoute {
	path: string;
	label: string;
	hide: boolean;
}

export default { routes };
