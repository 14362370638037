"use client";

import AppNavbar from "./navbar";
// import "../styles/globals.css";

export default function AppLayout({ children }: { children: React.ReactNode }) {
	return (
		<main className="bg-gray-200 h-screen">
			<AppNavbar />
			{children}
		</main>
	);
}
