"use client";
import dynamic from "next/dynamic";

import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/authContext";
import { logout } from "../../functions/api";

import app from "../../functions/app";

import { getCookie } from "cookies-next";

import fuchsAnimation from "../../public/icons/flunkerfuchs.json";

const Lottie = dynamic(() => import("lottie-react"), {
	ssr: false,
});

import { usePathname } from "next/navigation";
import { useRouter } from "next/navigation";
import {
	Navbar,
	NavbarBrand,
	NavbarContent,
	NavbarItem,
	NavbarMenu,
	NavbarMenuItem,
	NavbarMenuToggle,
} from "@nextui-org/navbar";
import { Link } from "@nextui-org/link";
import { authKey } from "../../pages/login";
import { Button } from "@nextui-org/button";

export default function AppNavbar(): JSX.Element {
	const context = useContext(AuthContext);
	const router = useRouter();
	const pathname = usePathname();

	const [isMenuOpen, setIsMenuOpen] = useState(false);

	useEffect(() => {
		if (!context.user && getCookie(authKey)) {
			const username = localStorage.getItem("username");
			context.setUser(username);
		}
	}, [context.user, context]);

	const onLogout = async () => {
		await logout();
		context.setUser(null);
		router.push(app.routes.login.path);
	};

	const showMenu = () => {
		return context.user && pathname !== app.routes.login.path;
	};

	const isActive = (route: string): boolean => {
		return pathname.startsWith(route);
	};

	const menuItems = () => {
		return Object.values(app.routes).filter((item) => !item.hide);
	};

	return (
		<Navbar
			onMenuOpenChange={setIsMenuOpen}
			isMenuOpen={isMenuOpen}
			isBordered
			disableAnimation
			className="py-2 bg-white md:drop-shadow-md"
			maxWidth="full"
			classNames={{
				item: [
					"flex",
					"relative",
					"h-full",
					"items-center",
					"data-[active=true]:after:content-['']",
					"data-[active=true]:after:absolute",
					"data-[active=true]:after:bottom-[-8px]",
					"data-[active=true]:after:rounded-md",
					"data-[active=true]:after:left-0",
					"data-[active=true]:after:right-0",
					"data-[active=true]:after:h-[6px]",
					"data-[active=true]:after:bg-primary",
				],
			}}
		>
			<NavbarBrand>
				<Lottie
					animationData={fuchsAnimation}
					loop={true}
					height={64}
					width={64}
					style={{ height: 64, width: 64, marginRight: "0.5em" }}
				/>
				<h1 className="font-semibold text-transparent text-2xl ml-3 md:ml-0 md:text-3xl bg-clip-text bg-gradient-to-r from-orange-400 to-red-600">
					Ladefuchs Admin
				</h1>
			</NavbarBrand>
			{showMenu() && (
				<>
					<NavbarContent className="p-0 tablet:flex !justify-end hidden gap-6">
						{menuItems().map((item) => {
							return (
								<NavbarItem
									key={item.path}
									isActive={isActive(item.path)}
								>
									<Link
										className={`text-lg font-bold  ${
											isActive(item.path)
												? ".text-primary"
												: "text-gray-800"
										}`}
										href={item.path}
									>
										{item.label}
									</Link>
								</NavbarItem>
							);
						})}
						<NavbarItem className="ml-3">
							<Button
								color="primary"
								variant="ghost"
								size="sm"
								className="text-md font-bold hover:text-white mt-[3px]"
								onClick={onLogout}
							>
								Logout
							</Button>
						</NavbarItem>
					</NavbarContent>

					<NavbarMenu className="pt-12 gap-y-6 pl-8">
						{menuItems().map((item) => (
							<NavbarMenuItem key={item.path}>
								<Link
									className="text-xl text-gray-900 font-bold"
									href={item.path}
									onClick={() => {
										setIsMenuOpen(false);
									}}
								>
									{item.label}
								</Link>
							</NavbarMenuItem>
						))}
						<NavbarMenuItem>
							<Link
								className="text-xl text-gray-900 font-bold"
								onClick={() => {
									setIsMenuOpen(false);
									onLogout();
								}}
							>
								Logout
							</Link>
						</NavbarMenuItem>
					</NavbarMenu>

					<NavbarMenuToggle
						aria-label={isMenuOpen ? "Close menu" : "Open menu"}
						className="tablet:hidden"
					/>
				</>
			)}
		</Navbar>
	);
}
