import { NextRequest, NextResponse } from "next/server";

export const apiEndpoint =
	process.env.NEXT_PUBLIC_API_ENDPOINT ?? "https://admin.ladefuchs.app/admin";

export default async function middleware(request: NextRequest) {
	const cookie = request.headers.get("cookie");

	if (cookie) {
		const status = await verifyLogin(cookie);

		if (status === 200) {
			return NextResponse.next();
		}
		return new NextResponse(
			JSON.stringify({
				success: false,
				message: "authentication failed",
			}),
			{ status: 401, headers: { "content-type": "application/json" } }
		);
	}

	return NextResponse.redirect(new URL("/404", request.url));
}

export const config = {
	matcher: ["/docs/", "/card_na.jpg", "/logs"],
	runtime: "nodejs",
};

export async function verifyLogin(cookieValue: string): Promise<number> {
	const options = {
		mode: "cors" as RequestMode,
		headers: {
			cookie: cookieValue,
			"Content-Type": "application/json",
			Middleware: "Admin API",
		},
	};

	try {
		const response = await fetch(`${apiEndpoint}/auth/confirm`, options);

		return response.status;
	} catch (error) {
		console.error(error);
	}
	return 401;
}
