import React, {
	createContext,
	Dispatch,
	SetStateAction,
	useState,
} from "react";

export interface User {
	username: string;
}

export interface AuthContextType {
	user: string | null;
	setUser: Dispatch<SetStateAction<string | null>>;
}

export const AuthContext = createContext<AuthContextType>({
	user: null,
	setUser: () => {},
});

interface Props {
	children: React.ReactNode;
}

export const AuthContextProvider = ({ children }: Props) => {
	const [user, setUser] = useState<string | null>(null);

	return (
		<AuthContext.Provider value={{ user, setUser }}>
			{children}
		</AuthContext.Provider>
	);
};
