"use client";

import { useContext, useEffect, useState } from "react";

import { Input } from "@nextui-org/input";
import { Button } from "@nextui-org/button";

import { useForm } from "react-hook-form";

import { useRouter } from "next/navigation";
import { useCookies } from "react-cookie";
import { verifyLogin } from "../middleware";
import { login } from "../functions/api";
import app from "../functions/app";
import { AuthContext, User } from "../contexts/authContext";
import { Card, CardBody } from "@nextui-org/card";

interface Inputs {
	username: string;
	password: string;
}

export const authKey = "auth_token";

export default function Login(): JSX.Element {
	const router = useRouter();
	const context = useContext(AuthContext);

	const [cookie, _setCookie, removeCookie] = useCookies([authKey]);

	const { register, handleSubmit, getValues, formState } = useForm<Inputs>();

	const [error, setError] = useState<null | string>(null);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (cookie.auth_token) {
			return;
		}
		verifyLogin(cookie.auth_token).then((resp) => {
			if (resp !== 200) {
				removeCookie(cookie.auth_token);
				return;
			}
			router.push(app.routes.dashboard.path);
		});
	}, [cookie.auth_token]);

	const onSubmit = async () => {
		setIsLoading(true);
		const { username, password } = getValues();
		try {
			const json = await login(username, password);
			setError(null);
			const user: User | null = json?.data;
			if (user) {
				localStorage.setItem("username", user.username);
				context.setUser(user.username);

				if (user.username.indexOf("docufuchs") > -1) {
					router.push(app.routes.documentation.path);
				} else {
					router.push(app.routes.tariffs.path);
				}
			}
		} catch (error: any) {
			const message = error?.response?.data?.reason ?? error.message;
			console.error({ error });
			setError(message);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
			<Card className="md:w-96 w-[320px] max-mobile-w:mx-4 px-2 py-2">
				<CardBody>
					<h2 className="font-bold text-3xl mb-5">Login</h2>
					{error && (
						<div>
							<p
								color="error"
								className="font-bold pb-4 text-center text-red-500"
							>
								{error}
							</p>
						</div>
					)}
					<form
						onSubmit={handleSubmit(onSubmit)}
						className="space-y-4"
					>
						<Input
							autoFocus
							isClearable
							autoComplete="off"
							label="Username"
							aria-label="username"
							size="md"
							tabIndex={1}
							{...register("username", {
								required: true,
								minLength: 4,
							})}
						/>
						<Input
							aria-label="Password"
							type="password"
							label="Password"
							tabIndex={2}
							{...register("password", {
								required: true,
								minLength: 3,
							})}
						/>
						<Button
							type="submit"
							tabIndex={3}
							color="primary"
							className="text-white w-full !mt-6 disabled:opacity-75 font-bold"
							isLoading={isLoading}
							isDisabled={!formState.isValid}
						>
							Sign In
						</Button>
					</form>
				</CardBody>
			</Card>
		</div>
	);
}
